<template>
  <div class="cont-box" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <img class="title-header" src="../assets/b-title-bg.png" />
    <header><span class="el-icon-back goBack" @click="$router.go(-1)"></span></header>
    <div class="list">
      <div class="title-cont">
        <img class="title-img" src="../assets/b-title-des.png" />
        <p class="title">{{$route.params.id}}市场体量及机会额</p>
        <img class="title-img resever" src="../assets/b-title-des.png" />
      </div>
      <div class="icon-list">
        <div class="icon">
          <p class="icon-title">体量总额<span>(万元)</span></p>
          <p class="icon-num">{{massSum?massSum:'--'}}</p>
        </div>
        <div class="icon">
          <p class="icon-title">机会总额<span>(万元)</span></p>
          <p class="icon-num">{{numSum?numSum:'--'}}</p>
        </div>
      </div>
      <div class="card-box">
        <ul v-for="(item, index) in datas" :key="index" class="card">
          <li>
            <label>销售员:</label>
            <p>{{item.name}}</p>
          </li>
          <li>
            <label>项目名称:</label>
            <p>{{item.xmmc}}</p>
          </li>
          <li>
            <label>一级产品:</label>
            <p>{{item.type}}</p>
          </li>
          <li>
            <label>项目体量金额<span>(万元):</span></label>
            <p>{{item.mass}}</p>
          </li>
          <li>
            <label>机会合同额<span>(万元):</span></label>
            <p>{{item.num}}</p>
          </li>
          <li class="tip" v-if="datas.length > 1"><span>{{index+1}}</span>/{{datas.length}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ReportDetail',
    data() {
      return {
        loading: true,
        datas: [],
        massSum: 0,
        numSum: 0
      }
    },
    mounted() {
      const province = this.$route.params.id
      this.$axios.post('/wechat/selectBYsf',{province}).then(res => {
        this.datas = res.data.province
        this.loading = false
        this.datas.map(item => {
          this.massSum += item.mass
          this.numSum += item.num
        })
      }).catch(err => {
        this.loading = false
      })
    }
  }
</script>

<style scoped>
  .cont-box {
    background-color: #08103c;
    min-height: 100%;
  }

  .title-header {
    width: 100%;
  }

  .resever {
    transform: scaleX(-1);
  }

  .title-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-img {
    width: 80px;
  }

  .title {
    color: #2dfcff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px;
    white-space: nowrap;
  }

  .list {
    padding-bottom: 40px;
  }
  
  .icon-list {
    padding: 30px 0 10px;
    display: flex;
    justify-content: center;
  }
  .icon {
    width: 40%;
    background: rgba(0, 0, 0, .2);
    margin: 0 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(45, 252, 255, .2);
  }
  .icon-title {
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
  }
  .icon-title>span{
    font-size: 12px;
  }
  .icon-num {
    font-size: 20px;
    font-weight: bold;
    padding-top: 6px;
    color: #2dfcff;
  }
  
  .icon:nth-of-type(2) .icon-num {
    color: #ff6f06;
  }
  .goBack {
    font-size: 16px;
    padding: 6px;
    color: #2dfcff;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: .8;
  }
  .card-box {
    margin-top: 20px;
    padding: 0 20px;
  }
  .card {
    font-size: 12px;
    color: rgba(255, 255, 255, .8);
    border-radius: 10px;
    background: linear-gradient(35deg, #0a3c5d, #091843);
    border: 1px solid rgba(0, 0, 0, .6);
    padding: 8px;
    position: relative;
    margin-bottom: 15px;
  }
  .card>li {
    display: flex;
    padding: 4px;
    align-items: center;
  }
  .tip{
    position: absolute;
    top: 2px;
    right: 4px;
    font-size: 10px;
    color: rgba(44, 252, 254, .4);
  }
  .tip > span {
    color: rgba(44, 252, 254, .8);
  }
  .card >label>span {
    font-size: 12px;
  }
  .card>li>label {
    white-space: nowrap;
    margin-right: 6px;
  }
  .card>li>p{
    color: #2DFCFF;
    font-weight: bold;
  }
</style>
